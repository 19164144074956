// header {
//     background-color: $offwhite;
//     padding: 15px 0 8px;
//     z-index: 999;
//     &.headspace {
//         height: auto;
//     }
//     &.headspace--hidden {
//         transform: translate3d(0, -100px, 0);
//     }
//     &.headspace--fixed {
//         box-shadow: -3px 3px 10px rgba(0,0, 0, 0.08);
//     }
//     .navbar {
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         svg {
//             width: 200px;
//             height: 60px;
//         }
//     }
// }

header {
    background-color: $white;
    padding: 15px 0 8px;
    z-index: 999;
    @media only screen and (max-width: 767px) {
        border-bottom: 1px solid $offwhite;
    }
    &.headspace {
        height: auto;
    }

    &.headspace--hidden {
        transform: translate3d(0, -100px, 0);
    }

    &.headspace--fixed {
        box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.08);
    }

    a {
        color: $black;
        @include Ease();

        &:hover {
            color: $base;
        }

        @media(max-width: 991px) {
            color: $white;

            &:hover {
                color: $white;
            }
        }
    }

    .MenuContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .LogoContainer {
            svg {
                width: 200px;
                height: 60px;
                @media only screen and (max-width: 767px) {
                    width: 180px;
                    height: 50px;
                }
            }
        }
    }

    .NavContainer {
        &.Active_Nav {
            .menu-main-menu-container {
                transform: translateX(0);
                z-index: 999;
                @media only screen and (max-width: 767px) {
                    width: 75vw;
                }
            }

            .MenuOverlay {
                visibility: visible;
                opacity: 1;
                z-index: 990;

                .CloseMenuIcon {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }

        .menu-main-menu-container {
            height: 100%;

            @media(max-width: 991px) {
                position: fixed;
                width: 375px;
                height: 100vh;
                overflow-y: scroll;
                right: 0;
                top: 0;
                background-color: $base;
                @include Ease();
                transform: translateX(100%);
            }

            @media(max-width: 500px) {
                width: 75vw;
                width: 0;
            }

            >ul {
                height: 100%;
                display: flex;
                margin: 0;
                padding: 0;

                @media(max-width: 991px) {
                    display: block;
                    padding-left: 0;
                    padding-top: 20px;
                }

                .sidebarMenu {
                    cursor: pointer;

                    &:hover {
                        span {
                            &:nth-child(2) {
                                width: 26px;
                            }
                        }
                    }
                }

                >li {
                    padding: 18px 45px 18px 0;
                    position: relative;
                    display: flex;
                    align-items: center;
                    z-index: 5;

                    @media(min-width: 1200px) {
                        padding: 18px 20px;
                    }

                    @media(max-width: 991px) {
                        padding: 0px;
                        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                        flex-direction: column;
                        align-items: unset;
                        background-color: transparent;
                        z-index: auto;
                    }

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;

                        span {
                            display: block;
                            height: 2px;
                            width: 26px;
                            background-color: $black;
                            margin-bottom: 7px;
                            @include Ease();

                            &:nth-child(2) {
                                width: 19px;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        @media(max-width: 991px) {
                            border-bottom: 0;
                        }
                    }

                    >a {
                        font-size: 1.6rem;
                        position: relative;
                        text-decoration: none;
                        text-transform: capitalize;

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            bottom: -3px;
                            background-color: $base;
                            width: 100%;
                            height: 1px;
                            transition: all 0.3s ease-in;
                            transform: scale(0);
                        }

                        @media(min-width:992px) {
                            &:hover {
                                &::after {
                                    transform: scale(1);
                                }
                            }
                        }

                        @media(max-width: 991px) {
                            font-size: 1.6rem;
                            padding: 15px 25px;
                            width: calc(100% - 50px);
                        }
                    }
                }

                .menu-item-has-children {
                    &.Active_MobileDropdown {
                        >ul {
                            height: auto;
                            opacity: 1;
                            z-index: 5;
                            @include Ease(0.3s);
                        }

                        li {
                            >a {
                                >span {
                                    &:before {
                                        transform: translate(-50%, -50%) rotate(-180deg);
                                    }
                                }
                            }
                        }
                    }

                    >a {
                        position: relative;
                        display: inline-block;

                        @media(max-width: 991px) {
                            display: block;
                            position: static;
                        }

                        &:before {
                            content: '';
                            background-image: url(../img/dow-arrow.png);
                            background-repeat: no-repeat;
                            background-size: 100%;
                            width: 10px;
                            height: 10px;
                            position: absolute;
                            right: -12px;
                            top: 55%;
                            transform: translateY(-50%);
                            @include Ease();

                            @media(max-width: 991px) {
                                content: unset;
                            }
                        }

                        >span {
                            display: none;

                            @media(max-width: 991px) {
                                right: 0;
                                top: 0;
                                position: absolute;
                                transform: translateY(0);
                                height: 52px;
                                width: 50px;
                                border-left: 1px solid rgba(255, 255, 255, 0.1);
                                background-color: darken($base, 3%);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }

                            &:before {
                                content: '';
                                width: 15px;
                                height: 15px;
                                background-image: url(../img/dow-arrow.png);
                                background-size: 100%;
                                background-repeat: no-repeat;
                                background-position: center;
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                @include Ease();
                            }
                        }
                    }

                    &:hover {
                        >a {
                            &:before {
                                transform: translateY(-50%) rotate(-180deg);

                                @media(max-width: 991px) {
                                    transform: translateY(-50%);
                                }
                            }
                        }

                        >ul {

                            @media(min-width: 992px) {
                                transform: scaleY(1) translateY(0);
                                visibility: visible;
                                opacity: 1;
                                z-index: 4;
                                @include Ease();
                            }

                            li {
                                a {
                                    opacity: 1;
                                    // @include Ease(0.1s);
                                }
                            }
                        }
                    }

                    >ul {
                        padding: 0;
                        position: absolute;
                        top: 100%;
                        min-width: 235px;
                        background-color: $black;
                        box-shadow: 0px 9px 20px rgba(0, 0, 0, 0.11);
                        transform: scaleY(0.5) translateY(-80%);
                        visibility: hidden;
                        opacity: 0;
                        z-index: -4;
                        @include Ease();

                        @media(max-width: 991px) {
                            z-index: -1;
                            opacity: 0;
                            transform: scaleY(1) translateY(0);
                            visibility: visible;
                            width: 100%;
                            position: relative;
                            background-color: darken($base, 3%);
                            height: 0;
                            @include Ease(0.3s);
                        }

                        // &:before{
                        //   content: '';
                        //   position: absolute;
                        //   top: -9px;
                        //   left:20px;
                        //   display: inline-block;
                        //   border-right: 9px solid transparent;
                        //   border-bottom: 9px solid #eee;
                        //   border-left: 9px solid transparent;
                        //   @media(max-width: 991px){
                        //     border: none;
                        //   }
                        // }
                        li {
                            @include Ease();

                            @media screen and (min-width: 992px) {
                                position: relative;

                                ul {
                                    position: absolute;
                                    max-width: 250px;
                                    top: 0;
                                    left: 100%;
                                }
                            }

                            @media(max-width: 991px) {
                                border-top: 1px solid rgba(255, 255, 255, 0.1);
                            }

                            &:last-child {
                                border-bottom: none;
                            }

                            a {
                                padding: 10px 15px;
                                display: inline-block;
                                width: 100%;
                                font-size: 1.5rem;
                                opacity: 0;
                                color: white;
                                text-transform: capitalize;
                                @include Ease(1s);
                                position: relative;
                                display: inline-block;

                                @media(max-width: 991px) {
                                    display: block;
                                    position: static;
                                }

                                @media(max-width: 991px) {
                                    padding: 10px 25px;
                                    width: 100%;
                                    opacity: 1;
                                    font-size: 1.55rem;
                                }
                            }

                            &:hover {
                                background-color: $yellow;
                            }

                            &.menu-item-has-children {
                                >a {
                                    &:before {
                                        content: '';
                                        background-image: url(../img/right-arrow.png);
                                        background-repeat: no-repeat;
                                        background-size: 100%;
                                        width: 10px;
                                        height: 10px;
                                        position: absolute;
                                        right: 12px;
                                        z-index: 9;
                                        top: 55%;
                                        transform: translateY(-50%);
                                        @include Ease();

                                        @media(max-width: 991px) {
                                            content: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .MenuButton {
                @media(max-width: 991px) {
                    padding-left: 25px;
                    padding-top: 15px;
                }

                a {
                    background-color: $base;
                    border-radius: 4px;
                    color: $white;
                    padding: 1.2rem 3rem;
                    border: 1px solid $base;
                    display: inline-block;
                    text-align: center;
                    @include Ease();

                    @media(max-width: 991px) {
                        color: $white;
                        min-width: 155px;
                        background-color: $base;
                        border: 1px solid $white;
                    }

                    &:hover {
                        background-color: $white;
                        color: $base;
                    }
                }
            }
        }
    }

    .MobileNavController {
        @media(min-width: 992px) {
            display: none;
        }
    }

    .MenuOverlay {
        background-color: rgba(51, 51, 51, 0.77);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // display: none;
        visibility: hidden;
        opacity: 0;
        z-index: -1;
        @include Ease();

        .CloseMenuIcon {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            cursor: pointer;
            position: absolute;
            transform: translateY(-20%);
            opacity: 0;
            text-decoration: none;
            @include Ease(0.4);

            @media(max-width: 991px) {
                right: 400px;
            }

            @media(max-width: 500px) {
                right: 78vw;
            }

            >span {
                font-size: 45px;
                line-height: 0;
            }
        }
    }

    .MenuBar {
        width: 35px;
        height: 25px;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);

        >div {}

        span {
            width: 100%;
            height: 2px;
            background-color: $black;
            display: inline-block;
            position: absolute;
            top: 0;
            transform: translateY(-50%);

            &:nth-child(2) {
                top: 50%;
                width: 50%;
                right: 0;
            }

            &:last-child {
                top: 100%;
                width: 75%;
                right: 0;
            }
        }
    }
}