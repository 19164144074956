.dotsBanner {
    position: relative;
    .heading {
        position: relative;
        padding-left: 15px;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 10px;
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background: $yellow;
        }
        h1 {
            font-size: 58px;
            margin-bottom: 0;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 1;
            color: $base;
            @media only screen and (max-width: 767px) {
                font-size: 28px;
            }
        }
    }
    p {
        font-size: 22px;
        @media only screen and (max-width: 767px) { 
            font-size: 16px;
        }
    }
    img {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }
}