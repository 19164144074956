.pointCards {
    background: rgba(#75B0FF, 0.18);
    .heading {
        position: relative;
        padding-left: 15px;
        margin-bottom: 50px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 10px;
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background: $yellow;
        }
        h2 {
            font-size: 58px;
            margin-bottom: 0;
            font-weight: 500;
            color: $base;
            letter-spacing: 2px;
            @media only screen and (max-width: 767px) {
                font-size: 28px;
                letter-spacing: 1px;
            }
        }
        & + p {
            font-size: 22px;
            margin-bottom: 50px;
            @media only screen and (max-width: 767px) {
                font-size: 16px;
                margin-bottom: 30px;
            }
        }
    }
    .pointCardWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .pointCard {
            background: $white;
            padding: 30px;
            flex-basis: calc(100% / 3 - 15px);
            margin-bottom: 25px;
            @include Ease();
            @media only screen and (max-width: 767px) {
                flex-basis: 100%;
            }
            h3 {
                font-size: 28px;
                color: $base;
                font-family: $secondaryFont;
                font-weight: 600;
                margin-bottom: 20px;
                @include Ease();
                @media only screen and (max-width: 767px) {
                    font-weight: 500;
                    margin-bottom: 10px;
                }
            }
            p {
                @include Ease();
                opacity: 1;
            }
            &:hover {
                background: $base;
                h3 {
                    color: $white;
                }
                p {
                    color: $white;
                    opacity: .8;
                }
            }
        }
    }
    
}