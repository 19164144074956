.onlyContent {
    h3, h2 {
        color: $base;
        letter-spacing: 1px;
        font-weight: 600;
    }
    // h3 {
    //     text-transform: capitalize;
    // }
    p {
        margin-bottom: 20px;
        letter-spacing: .8px;
    }
    ul {
        li {
            p {
                margin-bottom: 5px;
            }
        }
        margin-bottom: 20px;
    }
}