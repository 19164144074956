.industries {
    background: #C4B49A;
    h2 {
        color: $base;
        font-family: $secondaryFont;
        margin-bottom: 30px;
        font-size: 38px;
        @media only screen and (max-width: 767px) {
            font-size: 28px;
            letter-spacing: 1px;
            margin-bottom: 10px;
        }
    }
    p {
        // color: $black;
    }
    .itemsWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 40px;
        @media only screen and (max-width: 767px) {
            align-items: flex-start;
        }
        .item {
            background: $white;
            padding: 20px;
            margin-bottom: 15px;
            flex-basis: 19%;
            min-height: 175px;
            max-height: 175px;
            transition: background .4s 0s ease !important;
            &:last-child {
                @media only screen and (max-width: 767px) {
                    margin: auto;
                }
            }
            @media only screen and (max-width: 767px) {
                flex-basis: 48%;
                max-height: initial;
                min-height: initial;
                align-self: stretch;
                min-width: 48%;
            }
            svg {
                width: 45px;
                height: 45px;
                margin-bottom: 15px;
                transition: fill .4s 0s ease !important;
                fill: $base;
                @media only screen and (max-width: 767px) {
                    width: 30px;
                    height: 30px;
                }
            }
            p {
                color: $base;
                font-size: 20px;
                font-weight: 500;
                line-height: 1.2;
                transition: color .4s 0s ease !important;
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
            &:hover {
                background: $base;
                svg {
                    fill: $white;
                }
                p {
                    color: $white;
                }
            }
        }
    }
}