.upperFooter {
	background: linear-gradient(0deg, rgba($base, 0.66), rgba($base, 0.66)), url(../../assets/img/footer-bg.png);
  	background-size: cover;
	color: $white;
	.upperFooterWrapper {
		display: flex;
		align-items: flex-start;
		@media only screen and (max-width: 767px) {
			flex-direction: column;
		}
		.heading {
			position: relative;
			padding-left: 15px;
			margin-right: 10%;
			@media only screen and (max-width: 767px) {
				margin-bottom: 20px;
			}
			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				height: 100%;
				width: 3px;
				background: $yellow;
			}
			h2 {
				font-size: 38px;
				margin-bottom: 0;
				font-weight: 500;
				letter-spacing: 1px;
				line-height: 1;
			}
		}
		.contentWrap {
			flex-basis: 50%;
			h3 {
				font-size: 28px;
				font-weight: 400;
				margin-bottom: 20px;
				font-family: $secondaryFont;
			}
			p {
				a {
					color: $yellow;
					@include link($yellow);
					@media only screen and (max-width: 767px) {
						display: inline-block;
					}
				}
			}
		}
	}
}

footer {
	background: $darkblue;
	padding: 20px 0 0;
	color: $white;
	.footerNavigation {
		padding-bottom: 15px;
		margin-bottom: 15px;
		@media screen and (min-width: 768px) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid $offwhite;
		}
		svg {
            width: 200px;
            height: 60px;
        }
		.menu {
			@media only screen and (max-width: 767px) {
				display: block;
			}
			.menu-items {
				margin-left: 0;
				@media only screen and (min-width:768px) {
					margin-left: 30px;
					&:first-child {
						margin-left: 0;
					}
				}
				@media only screen and (max-width: 767px) {
					margin-bottom: 15px;
				}
				.menu-item { 
					color: $white;
					@include link($yellow);
					font-size: 16px;
					letter-spacing: initial;
				}
			}
		}
		.social {
			display: flex;
			padding: 0;
			list-style: none;
			margin: 0;
			@media only screen and (max-width: 767px) {
				margin-top: 40px;
			}
			li {
				margin-left: 20px;
				&:first-child {
					margin-left: 0;
					a {
						svg {
							fill: #4267B2;
						}
						&:hover {
							background: #4267B2;
							svg {
								fill: $white;
							}
						}
					}
				}
				&:last-child {
					a {
						svg {
							fill: #1DA1F2;
						}
						&:hover {
							background: #1DA1F2;
							svg {
								fill: $white;
							}
						}
					}
				}
				a {
					@include Ease();
					background: $white;
					padding: 8px 10px 10px;
					svg {
						width: 20px;
						height: 20px;
						fill: #0e76a8;
						@include Ease();
						vertical-align: middle;
					}
					&:hover {
						background: #0e76a8;
						svg {
							fill: $white;
						}
					}
				}
			}
		}
	}
	.footerMeta {
		padding-top: 15px;
		a {
			color: $white;
			text-decoration: none;
			@include Ease();
			&:hover {
				opacity: .60;
			}
		}
	}
	.footerLeft {
		p {
			letter-spacing: 0;
		}
	}
	.footerRight {
		h3 {
			font-size: 28px;
			font-weight: 400;
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 20px;
			@media only screen and (min-width: 768px) {
				padding-right: 30%;
			}
		}
		form {
			display: flex;
			align-items: flex-end;
			justify-content: flex-start;
		}
		.magicBox {
			position:relative;
			display:inline-block;
			bottom: -2px;
			@media only screen and (min-width: 768px) {
				width: 60%;
			}
			@media only screen and (max-width: 767px) {
				bottom: -2.5px;
			}
			label {
				// @media only screen and (max-width: 767px) {
					position: absolute;
					bottom: -30px;
					left: 0;	
				// }
			}
			&::after, &::before {
				content: ' ';
				width:0%;
				bottom:0px;
				left: 50%;
				transform: translate(-50%, -50%);
				position:absolute;
				transition: ease-in-out .4s all;
			}
			
			&::before {
				width:100%;
				border-bottom: 4px solid $offwhite;
			}
			
			&::after {
				border-bottom: 4px solid $yellow;
			}
			
			&:focus-within::after {
				width:100%;
			}
		}
		input {
			border: none;
			// border-bottom: 1px solid $offwhite;
			background: none;
			color: $white;
			padding: 10px 0;
			width: 100%;
		}
		// .submit {
		// 	background-color: $yellow;
		// 	padding: 10px 20px;
		// 	display: inline-block;
		// 	cursor: pointer;
		// 	@include Ease();
		// 	svg {
		// 		fill: $white;
		// 		height: 25px;
		// 		width: 25px;
		// 		vertical-align: middle;
		// 		@include Ease();
		// 	}
		// 	&:hover {
		// 		background-color: $white;
		// 		svg {
		// 			fill: $base;
		// 		}
		// 	}
		// }
		input[type="submit"]{
			background: $yellow url(../img/send.svg) no-repeat;
			border: none;
			width: 55px;
			height: 50px;
			cursor: pointer;	
			border:none;
			outline:none;
			background-position: 15px;	
			@include Ease();	    
			&:hover{
				background-color: $base;
			}
		}
	}
	.copyright {
		margin-top: 40px;
		opacity: .60;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		@media only screen and (max-width: 767px) {
			flex-direction: column;
			justify-content: center;
			text-align: center;
		}
		a {
			color: $white;
			text-decoration: none;
			@include link($white);
		}
		svg {
			width: 18px;
			height: 18px;
			vertical-align: middle;
			fill: 	#e31b23;
		}
	}
}