.rightVideoLeftContent {
    // border-top: 1px solid $offwhite;
    // border-bottom: 1px solid $offwhite;
    // .container {
    //     position: relative;
    //     &::before, &::after {
    //         position: absolute;
    //         content: "";
    //         width: 100%;
    //         height: 2px;
    //         background-color: $offwhite;
    //         left: 0;
    //     }
    //     &::before {
    //         top: -8rem;
    //     }
    //     &::after {
    //         bottom: -8rem;
    //     }
    // }
    .videoContainer {
        position: relative;
        text-align: right;
        &::before {
            content: "";
            position: absolute;
            top: -15px;
            left: -15px;
            width: 100px;
            height: 100px;
            background: $yellow;
            z-index: -1;
            @media only screen and (max-width: 767px) {
                content: none;
            }
        }
        .buttonWithBG {
            @media only screen and (max-width: 767px) {
                display: none;
            }
        }
        span {
            @media only screen and (min-width: 768px) {
                width: 50px;
                height: 1px;
                background: $yellow;
                display: inline-block;
                position: absolute;
                top: calc(50% - 50px);
                transform: translate(-50%);
                right: -60px;
                &::after, &::before {
                    content: "";
                    position: absolute;
                    background: $yellow;
                    width: 100px;
                    height: 1px;
                    right: 0;
                }
                &::before {
                    top: -30px;
                }
                &::after {
                    top: 30px;
                }
            }
        }
    }
    .content {
        @media only screen and (min-width: 768px) {
            padding-left: 10%;
        }
        video {
            margin: 20px 0 10px;
        }
        .heading {
			position: relative;
			padding-left: 15px;
            @media only screen and (min-width: 768px) {
                margin-right: 10%;
                margin-bottom: 30px;
            }
			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				height: 100%;
				width: 3px;
				background: $yellow;
			}
			h2 {
				font-size: 58px;
				margin-bottom: 0;
                font-weight: 500;
                color: $base;
                letter-spacing: 2px;
                @media screen and (max-width: 767px) {
                    font-size: 28px;
                    letter-spacing: 1px;
                }
			}
		}
    }
}