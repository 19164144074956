@mixin Overlay() {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@mixin Ease($time: 0.4s) {
	transition: all ease $time;
}

@mixin FlexCenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin link($color: $base) {
  text-decoration: none;
  position: relative;
  @include Ease();
  &:hover {
      color: $color;
      &::after {
          transform: scaleX(1);
          transform-origin: left;
      }
  }
  &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      bottom: -5px;
      left: 0;
      background: $color;
      transition: transform 0.3s;
      transform: scaleX(0);
      transform-origin: right;
  }
}