.serviceCard {
    background: #C4B49A;
    .cardWrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .card {
            flex-basis: 49%;
            background: $offwhite;
            padding: 30px;
            margin-bottom: 25px;
            @include Ease();
            @media only screen and (max-width: 767px) {
                flex-basis: 100%;
            }
            &:last-child {
                flex-basis: 100%;
                @media only screen and (max-width: 767px) {
                    margin-bottom: 0;
                }
                ul {
                    list-style: none;
                    display: flex;
                    align-items: center;
                    @media only screen and (max-width: 767px) {
                        align-items: flex-start;
                        flex-direction: column;
                    }
                    li {
                        position: relative;
                        margin-right: 100px;
                        &:last-child {
                            margin-right: 0;
                        }
                        &::before {
                            content: "\25A0";
                            position: absolute;
                            left: -35px;
                            color: $base;
                            font-size: 24px;
                            top: -7px;
                            @include Ease();
                        }
                        p {
                            line-height: 2;
                        }
                    }
                }
            }
            h3 {
                margin-bottom: 20px;
                font-size: 28px;
                color: $base;
                font-weight: 500;
                letter-spacing: 1px;
                @include Ease();
            }  
            p {
                line-height: 2;
                @include Ease();
            }
            &:hover {
                background: $base;
                h3 {
                    color: $white;
                }
                p {
                    opacity: .6;
                    color: $white;
                }  
                ul {
                    li {
                        &::before {
                            opacity: .6;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}