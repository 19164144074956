.qoutes {
    text-align: center;
    position: relative;
    svg {
        position: absolute;
        top: calc(50% - 30px);
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: -1;
        fill: #005fb861;
        width: 130px;
        height: 130px;
    }
    h2 {
        font-weight: 400;
        font-size: 28px;
        letter-spacing: 1.25px;
        line-height: 1.6;
        margin-bottom: 15px;
        @media only screen and (max-width: 767px) {
            letter-spacing: 1px;
        }
    }
    p {
        font-size: 26px;
        color: $base;
        font-weight: 400;
        @media only screen and (max-width: 767px) {
            font-size: 22px;
        }
    }
}

.qoutesWithBG {
    background: $base;
    h2 {
        color: $white;
    }
    p {
        color: $yellow;
        position: relative;
        display: inline-block;
        &::after {
            position: absolute;
            content: "";
            width: 80%;
            height: 2px;
            background: $yellow;
            bottom: -10px;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 9;
        }
    }
    svg {
        fill: $white;
        z-index: 9;
        width: 70px;
        height: 70px;
        top: calc(50% - 40px);
    }
}