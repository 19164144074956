.onlyPara {
    .heading {
        position: relative;
        padding-left: 15px;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 10px;
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background: $yellow;
        }
        h2 {
            font-size: 58px;
            margin-bottom: 0;
            color: $base;
            font-weight: 500;
            @media only screen and (max-width: 767px) {
                font-size: 28px;
                letter-spacing: 1px;
            }
        }
    }
    p {
        font-size: 22px;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}

.onlyParaBlue {
    .heading {
        padding-left: 0;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 10px;
        }
        &::before {
            content: none;
        }
        h2 {
            font-size: 48px;
            color: $white;
            // font-family: $secondaryFont;
            line-height: 1.4;
            font-weight: 500;
            letter-spacing: 2px;
            @media only screen and (max-width: 767px) {
                font-size: 28px;
                letter-spacing: 1px;
            }
            br {
                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
        }
    }
    p {
        // opacity: .6;
        a {
            @include link($yellow);
            color: $yellow;
        }
    }
}

.beforeFooter {
    margin-bottom: 50px;
}