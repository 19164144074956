.thereeColumns {
    background: $offwhite;
    h3 {
        color: $base;
        font-size: 24px;
        font-family: $secondaryFont;
        font-weight: 600;
        text-align: center;
        line-height: 1.8;
        & + svg {
            fill: $yellow;
            width: 65px;
            height: 65px;
            position: absolute;
            right: -15px;
            top: -5px;
            &#plus {
                width: 50px;
                height: 50px;
                right: -25px;
            }
        }
    }
    .hideAndShow {
        // display: none;
        .threeColumnWrap {
            background: $white;
            padding: 15px;
            margin-top: 40px;
            @media only screen and (max-width:767px) {
                margin-top: 20px;
            }
            ul {
                list-style: none;
                li {
                    position: relative;
                    &::before {
                        content: "\25A0";
                        position: absolute;
	                    left: -35px;
                        color: $base;
                        font-size: 24px;
                        top: -7px;
                    }
                    p {
                        line-height: 2;
                        opacity: 1;
                    }
                }
            }
        }
    }
    #loadMorePoints {
        font-size: 24px;
        text-decoration: none;
        color: $black;
        @include Ease();
        margin-top: 50px;
        display: inline-block;
        letter-spacing: 1px;
        svg {
            width: 20px;
            height: 20px;
            vertical-align: middle;
            margin-left: 10px;
            @include Ease();
            &.arrowDown {

            }
            &.arrowUp {
                transform: rotate(-180deg);
            }
        }
        &:hover {
            opacity: .5;
        }
    }
}