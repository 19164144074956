.videoSliderBanner {
    .videoBannerWrapper {
        position: relative;
        height: calc(100vh - 80px);
        color: $white;
        text-align: center;
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: rgba($base, 0.5);
            display: block;
            z-index: 1;
        }
        video {
            position: absolute;
            z-index: -1;
            top: 0;
            display: block;
            &[poster] {
                object-fit: cover;
            }
            @media screen and (max-width: 767px) {
                height: 100%;
            }
        }
        .videoSlide {
            position: relative;
            top: 50%;
            left: 50%;
            width: 60%;
            transform: translate(-50%, -50%);
            z-index: 9;
            @media screen and (max-width: 767px) {
                width: 100%;
                top: 40%;
            }
            .items {
                .heading {
                    margin-bottom: 15px;
                    h1 {
                        margin-bottom: 0;
                        font-weight: 500;
                        letter-spacing: 1px;
                        color: $white;
                        opacity: 1;
                        font-size: 58px;
                        @media screen and (max-width: 767px) {
                            font-size: 28px;
                        }
                    }
                }
                p {
                    opacity: 1;
                }
            }
        }
        .sliderArrows {
            display: flex;
            align-items: center;
            justify-content: space-between;
            top: 50%;
            transform: translateY(-50%);
            z-index: 9;
            position: absolute;
            width: 100%;
            @media screen and (max-width: 767px) {
                display: none;
            }   
            .prevSlide, .nextSlide {
                border-radius: 50%;
                // border: 1px solid $base;
                display: inline-block;
                padding: 10px;
                width: 80px;
                height: 80px;
                @include FlexCenter;
                // background: $white;
                @include Ease();
                box-shadow: none;
                svg {
                    fill: $base;
                    width: 25px;
                    height: 25px;
                    @include Ease();
                }
                &:hover {
                    background: $base;
                    box-shadow: 0 2px 0 0 rgba(0, 0, 0, .1);
                    svg {
                        fill: $white;
                    }
                }
            }
            .nextSlide {
                margin-right: 5%;
            }
            .prevSlide {
                margin-left: 5%;
                svg {
                    transform: rotate(180deg);
                }
            } 
        }
        .yellowBtn {
            bottom: 10%;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9;
            @media screen and (max-width: 767px) {
                bottom: 25%;
            }
        }
    }
}