* {
  box-sizing: border-box;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
html {
  font-size: 62.5%;
  background: $white;
  scroll-behavior: smooth;
}
body {
  font: 400 16px/1.8 $secondaryFont;
  color: $black;
  // transition: all .4s .3s ease-in;
}
body,html{
	min-height: 100vh;
  padding: 0;
  margin: 0;
	overflow-x: hidden;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
  letter-spacing: .8px;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: $primaryFont;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
  letter-spacing: 1px;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6{
  font-size: 1rem;
}

img {
  max-width: 100%;
}

.DesktopOnly{
  display: none;
  @media (min-width: 768px) {
      display: block;
    }
}

.MobileOnly{
  @media (min-width: 768px) {
      display: none;
    }
}

.MobileSpaceOnly {
  padding-top: 3rem;
  @media (min-width: 768px) {
    padding-top: 0;
  }
}

input, textarea, button {
  outline: none;
}

video {
  width: 100%;
  height: auto;
}

.parallax {
  min-height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 767px) {
    min-height: 250px;
  }
}

.onlyImage {
  background-image: url(../../assets/img/team.png);
}

.blueBg {
  background: $base;
  color: $white;
}

.onlyVideo {
  // .videoOverlay {
  //   position: relative;
  //   &::before {
  //     position: absolute;
  //     content: "";
  //     width: 100%;
  //     height: 100%;
  //     background: rgba($base, 0.4);
  //     border-radius: 20px;
  //   }
  // }
  video {
    display: block;
    border-radius: 20px;
  }
  .heading {
    position: relative;
    padding-left: 15px;
    margin-bottom: 80px;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      background: $yellow;
    }
    h1 {
      font-size: 58px;
      margin-bottom: 0;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 1;
      color: $base;
    }
  }
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.line-height-2 {
  line-height: 2;
}

.center {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.onlyPara {
  &.singleHeading {
    p {
      font-size: 40px;
      color: $white;
      font-weight: 600;
      line-height: 1.4;
      @media only screen and (max-width: 767px) {
        font-size: 28px;
        font-weight: 500;
      }
    }
    min-height: 400px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../assets/img/team-2.jpg);
    display: flex;
    align-items: center;
    position: relative;
    @media only screen and (max-width: 767px) {
      min-height: 250px;
    }
    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: rgba($base, 0.8);
    }
  }
}


#scroll {
  position: fixed;
  left: 2.5rem;
  bottom: 12rem;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: none;
  border-radius: 50%;
  z-index: 99999;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, .1);
  transform: rotate(-180deg);
  background-color: $base;
  border: 1px solid $base;
  transition: all ease .3s;
  svg {
    width: 20px;
    height: 20px;
    fill: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all ease .3s;
  }
  &:hover {
    background: $white;
    svg {
      fill: $base;
    }
  }
}

.psuedoEl {
  position: relative;
  z-index: 99;
  &::before {
    content: "";
    position: absolute;
    top: 110px;
    left: -15px;
    width: 100px;
    height: 100px;
    background: #fc0;
    z-index: -1;
    @media only screen and (max-width: 767px) {
      content: none;
    }
  }
}

.iti__flag {background-image: url("../../assets/components/intltelinput/flags.png");}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {background-image: url("../../assets/components/intltelinput/flags@2x.png");}
}

.iti__selected-flag {
  height: initial;
}

.hide {
  display: none;
}

.error, #error-ms {
	color: #ff0000;
}
.success, #valid-msg {
	color: #008000;
}
.process {
	color: #ffb827;
}
.FormMessage {
	display: flex;
  align-items: center;
  justify-content: center;
	padding-top: 1em;
}


[data-scroll] {
  transition: opacity 1s;
}
[data-scroll="in"] {
  opacity: 1;
}
[data-scroll="out"] {
  opacity: 0;
}