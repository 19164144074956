@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-Italic.woff2') format('woff2'),
        url('../fonts/IvyMode-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-Bold.woff2') format('woff2'),
        url('../fonts/IvyMode-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-Regular.woff2') format('woff2'),
        url('../fonts/IvyMode-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-Light.woff2') format('woff2'),
        url('../fonts/IvyMode-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-LightItalic.woff2') format('woff2'),
        url('../fonts/IvyMode-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-BoldItalic.woff2') format('woff2'),
        url('../fonts/IvyMode-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-SemiBold.woff2') format('woff2'),
        url('../fonts/IvyMode-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-Thin.woff2') format('woff2'),
        url('../fonts/IvyMode-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-ThinItalic.woff2') format('woff2'),
        url('../fonts/IvyMode-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Ivy Mode';
    src: url('../fonts/IvyMode-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/IvyMode-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}


/* poppins-100 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/poppins-v15-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-100.woff') format('woff'); /* Modern Browsers */
}
/* poppins-100italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/poppins-v15-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-100italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-200 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/poppins-v15-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-200.woff') format('woff'); /* Modern Browsers */
}
/* poppins-200italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  src: url('../fonts/poppins-v15-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-200italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-300 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/poppins-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-300.woff') format('woff'); /* Modern Browsers */
}
/* poppins-300italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/poppins-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-300italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-regular - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/poppins-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-regular.woff') format('woff'); /* Modern Browsers */
}
/* poppins-italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/poppins-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-500 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/poppins-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-500.woff') format('woff'); /* Modern Browsers */
}
/* poppins-500italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/poppins-v15-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-500italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-600 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/poppins-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-600.woff') format('woff'); /* Modern Browsers */
}
/* poppins-600italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/poppins-v15-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-600italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-700 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/poppins-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-700.woff') format('woff'); /* Modern Browsers */
}
/* poppins-700italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/poppins-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-700italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-800 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/poppins-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-800.woff') format('woff'); /* Modern Browsers */
}
/* poppins-800italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/poppins-v15-latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-800italic.woff') format('woff'); /* Modern Browsers */
}
/* poppins-900 - latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/poppins-v15-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-900.woff') format('woff'); /* Modern Browsers */
}
/* poppins-900italic - latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/poppins-v15-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/poppins-v15-latin-900italic.woff') format('woff'); /* Modern Browsers */
}