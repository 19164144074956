.yellowBtn {
    background: $yellow;
    text-decoration: none;
    text-transform: capitalize;
    color: $black;
    padding: 10px 25px;
    display: inline-block;
    vertical-align: middle;
    transform: perspective(1px) translateZ(0);
    position: relative;
    transition-property: color;
    transition-duration: 0.5s;
    &::before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: $base;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition-property: transform;
        transition-duration: 0.5s;
        transition-timing-function: ease-out;
    }
    &:hover {
        color: $white;
        &::before {
            transform: scaleX(1);
            transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
        }
    }

    &.mt-15 {
        margin-top: 15px;
    }

    &.withBackBG {
        
    }
}

.buttonWithBG {
    position: relative;
    text-transform: capitalize;
    text-decoration: none;
    color: $white;
    padding: 25px 40px;
    background: $base;
    z-index: 99;
    top: -25px;
    font-size: 20px;
    @include Ease();
    display: inline;
    svg {
        width: 25px;
        height: 20px;
        vertical-align: middle;
        fill: $white;
        margin-left: 10px;
        @include Ease();
    }
    &::before {
        position: absolute;
        content: "";
        width: 70px;
        height: 60px;
        background: $yellow;
        top: -10px;
        left: -10px;
        z-index: -1;
        @include Ease();
    }
    &:hover {
        background: $yellow;
        color: $black;
        &::before {
            top: 0;
            left: 0;
        }
        svg {
            fill: $black;
        }
    }
}

.bigButton {
    text-transform: capitalize;
    text-decoration: none;
    color: $white;
    padding: 25px 80px;
    background: $base;
    font-size: 26px;
    line-height: 2;
    display: inline-block;
    @include Ease();
    @media only screen and (max-width: 767px) {
        padding: 25px 40px;
        width: 100%;
    }
    svg {
        width: 50px;
        height: 50px;
        vertical-align: middle;
        fill: $white;
        margin-left: 30px;
        @include Ease();
        border: 1px solid $white;
        padding: 12px;
        border-radius: 50%;
        @media only screen and (max-width: 767px) {
            width: 45px;
            height: 45px;
        }
    }
    &:hover {
        background: $yellow;
        color: $black;
        svg {
            fill: $black;
            border-color: $black;
            transform: rotate(360deg);
        }
    }
}

.goBack {
    margin-top: 50px;
    padding-left: 40px;
    padding-right: 40px;
  }