.onlyHeading {
    h2, h3 {
        font-family: $secondaryFont;
    }
    h2 {
        color: $yellow;
        font-size: 38px;
        font-weight: 500;
        margin-bottom: 20px;
        letter-spacing: 1px;
        @media only screen and (max-width: 767px) {
            font-size: 28px;
            margin-bottom: 10px;
        }
    }
    h3 {
        font-size: 24px;
        font-weight: 400;
        @media only screen and (max-width: 767px) {
            font-size: 18px;
            line-height: 1.4;
        }
    }
}