.menu {
    display: flex;
    align-items: center;
    padding: 0;
    list-style: none;
    .menu-items {
        margin-left: 50px;
        &:first-child {
            margin-left: 0;
        }
        .menu-item {
            color: $black;
            text-transform: capitalize;
            @include link($base);
            font-size: 18px;
            letter-spacing: 1px;
        }
    }
}