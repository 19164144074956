.contactFormSection {
    .map {
        position: relative;
        height: 100%;
        &::before {
            position: absolute;
            content: "";
            width: 85%;
            height: 70%;
            background: $base;
            top: 70px;
            left: -20px;
            z-index: -1;
            @media only screen and (max-width: 767px) {
                content: none;
            }
        }
        iframe {
            width: 100%;
            height: 80%;
            @media only screen and (max-width: 767px) {
                height: 400px;
            }
        }
    }
    .contactNumbers {
        ul {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: space-around;
            @media only screen and (max-width: 767px) {
                flex-direction: column;
                align-items: flex-start;
            }
            li {
                position: relative;
                &::before {
                    content: "\25A0";
                    position: absolute;
                    left: -35px;
                    color: $base;
                    font-size: 24px;
                    top: -7px;
                }
                p {
                    letter-spacing: 0;
                }
                a {
                    text-decoration: none;
                    color: $base;
                }
            }
        }
    }
    .formContainer {
        padding: 50px;
        padding-top: 0px;
        background: $white;
        // margin-top: -60px;
        @media screen and (min-width: 992px) {
            display: flex;
            justify-content: space-between;
        }
        @media only screen and (max-width: 767px) {
            padding: 30px 0 0;
        }
    }
    form {
        // margin-top: 20px;
        label {
            display: none;
        }
        .halfColumn {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .inputFieldGroup { 
                flex-basis: 47%;
            }
        }
        .inputFieldGroup {
            margin-bottom: 25px;
            // label {
            //     color: $black;
            //     font-size: 14px;
            //     // margin-bottom: 5px;
            //     display: block;
            //     opacity: .6;
            // }
            input:not([type=tel]), textarea {
                width: 100%;
                border: none;
                border-bottom: 1px solid grey;
                font-size: 14px;
                padding: 10px 0;
                @include Ease(0.3s);
                &:focus {
                    border-bottom-color: $black;
                    color: $black;
                }
            }
            .iti input, .iti input[type=tel], .iti input[type=text] {
                border: none;
                border-bottom: 1px solid grey;
                @media only screen and (min-width: 768px) {
                    padding-right: 60px;
                }
                &:focus {
                    border-bottom-color: $black;
                    color: $black;
                }
            }
        }
        .yellowBtn {
            cursor: pointer;
            margin-top: 10px;
            background: $base;
            color: $white;
            border: none;
            letter-spacing: 1px;
            @media only screen and (max-width: 767px) {
                margin-top: 0;
            }
            &::before { 
                background: $yellow;
            }
            &:hover {
                color: $black;
                svg {
                    fill: $black;
                    transform: rotate(45deg);
                }
            }
            svg {
                width: 20px;
                height: 20px;
                fill: $white;
                vertical-align: middle;
                margin-left: 10px;
                @include Ease();
            }
        }
        small {
            display: inline-block;
            opacity: .58;
            margin-top: 10px;
            a {
                color: $black;
            }
        }
    }
}