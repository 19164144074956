.ctaWithBG {
    .innerWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: $base;
        padding: 50px 70px;
        @media only screen and (max-width: 767px) {
            padding: 30px;
            flex-direction: column;
            align-items: flex-start;
        }
        .heading {
			position: relative;
			padding-left: 15px;
            flex-basis: 49%;
			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				height: 100%;
				width: 3px;
				background: $yellow;
			}
			h2 {
				font-size: 28px;
				margin-bottom: 0;
                font-weight: 500;
                color: $white;
                letter-spacing: 1px;
			}
            br {
                @media only screen and (max-width: 767px) {
                    display: none;
                }
            }
		}
        .bgWithBtn {
            flex-basis: 49%;
            text-align: center;
            background: url(../../assets/img/arrow-bg.svg);
            background-repeat: no-repeat;
            background-size: cover;
            @include FlexCenter;
            height: 100px;
            @media only screen and (max-width: 767px) {
                margin-top: 30px;
            }
            a {
                padding-left: 40px;
                padding-right: 40px;
                &::before {
                    background: $white;
                }
                &:hover { 
                    color: $base;
                }
            }
        }
    }
}