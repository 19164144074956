.rightContentLeftVideo {
    background: $offwhite;
    position: relative;
    .contentWrapper {
        @media only screen and (min-width: 768px) {
            padding-right: 10%;
        }
        .heading {
			position: relative;
			padding-left: 15px;
            margin-bottom: 30px;
            @media only screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
			&::before {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				height: 100%;
				width: 3px;
				background: $yellow;
			}
			h2, h1 {
				font-size: 58px;
				margin-bottom: 0;
                font-weight: 500;
                color: $base;
                letter-spacing: 2px;
                @media only screen and (max-width: 767px) {
                    letter-spacing: 1px;
                    font-size: 28px;
                }
			}
		}
        p {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        img {
            @media only screen and (max-width: 767px) {
                display: block;
            }
        }
        .authorAndSocial {
            margin-bottom: 20px;
            .author {
                background: $base;
                color: $white;
                padding: 15px 25px;
                p {
                    margin-bottom: 0;
                    &:first-child {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
            .social {
                display: flex;
                padding: 0;
                list-style: none;
                margin: 0;
                margin-top: 20px;
                li {
                    margin-left: 20px;
                    &:first-child {
                        margin-left: 0;
                    }
                    a {
                        @include Ease();
                        background: $base;
                        padding: 10px 15px 13px;
                        svg {
                            width: 15px;
                            height: 15px;
                            fill: $white;
                            @include Ease();
                            vertical-align: middle;
                            position: initial;
                            top: initial;
                            right: initial;
                        }
                        &:hover {
                            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }
        }
    }
    .videoAndSocial {
        @include FlexCenter;
        height: 100%;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        z-index: 99;
        &::before {
            content: "";
            position: absolute;
            background: $yellow;
            width: 100px;
            height: 100px;
            top: 200px;
            left: -15px;
            z-index: -1;
            @media only screen and (max-width: 767px) {
                content: none;
            }
        }
        video {
            z-index: 99;
        }
        .authorAndSocial {
            .author {
                background: $base;
                color: $white;
                padding: 15px 25px;
                top: -50px;
                position: relative;
                z-index: 99;
                // &::before {
                //     position: absolute;
                //     content: "";
                //     width: 70px;
                //     height: 70px;
                //     top: -10px;
                //     left: -10px;
                //     background: $yellow;
                //     z-index: -1;
                // }
                p {
                    margin-bottom: 0;
                    &:first-child {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
            .social {
                display: flex;
                padding: 0;
                list-style: none;
                margin: 0;
                // margin-top: 30px;
                li {
                    margin-left: 20px;
                    &:first-child {
                        margin-left: 0;
                    }
                    a {
                        @include Ease();
                        background: $base;
                        padding: 10px 10px 13px;
                        svg {
                            width: 25px;
                            height: 25px;
                            fill: $white;
                            @include Ease();
                            vertical-align: middle;
                            position: initial;
                            top: initial;
                            right: initial;
                        }
                        &:hover {
                            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
                        }
                    }
                }
            }
        }
    }
    .imageWithEl {
        position: relative;
        z-index: 99;
        &::before {
            content: "";
            position: absolute;
            background: $yellow;
            width: 100px;
            height: 100px;
            top: -15px;
            left: -15px;
            z-index: -1;
        }
        &.flexCenter {
            &::before {
                top: 75px;
            }
        }
    }
    svg {
        position: absolute;
        right: 0;
        top: 50%;
        width: 45%;
    }
    &.whiteBG {
        background: $white;
    }
    &.whoWeAre {
        .videoAndSocial {
            &::before {
                top: 260px;
            }
        }
        .imageWithEl {
            margin-top: 80px;
        }
        .goBack {
            margin-top: 10px;
        }
    }
    &.whyUs {
        .imageWithEl {
            &::before {
                top: 100px;
                @media only screen and (max-width: 767px) {
                    content: none;
                }
            }
        }
    }
}