.mapSection {
    background: url(../../assets/img/map.svg);
    background-repeat: no-repeat;
    background-position-x: 580px;
    background-size: 60%;
    background-position-y: 50px;
    @media only screen and (max-width: 767px) {
        background: none;
    }
    .heading {
        position: relative;
        padding-left: 15px;
        margin-bottom: 30px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 20px;
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            height: 100%;
            width: 3px;
            background: $yellow;
        }
        h2 {
            font-size: 58px;
            margin-bottom: 0;
            font-weight: 500;
            letter-spacing: 1px;
            line-height: 1;
            color: $base;
            @media only screen and (max-width: 767px) {
                font-size: 28px;
            }
        }
    }
    svg {
        @media only screen and (max-width: 767px) {
            // width: 200px;
            display: none;
        }
    }
}